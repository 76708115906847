import React, { useState, createContext } from "react";

export const LogoAnimationContext = createContext("");

const LogoAnimationProvider = ({ children }) => {
    const [currentAnimationName, setCurrentAnimationName] = useState("default");

    return (
        <LogoAnimationContext.Provider value={{ currentAnimationName, setCurrentAnimationName }}>
            {children}
        </LogoAnimationContext.Provider>
    );
};

export default LogoAnimationProvider;
